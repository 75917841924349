const selectors = {
  mediaSize: (state) => state.media.mediaSize,
  list: (state) => state.media.list,
  filters: (state) => state.media.filters,
  pagination: (state) => state.media.pagination,

  loadingListStatus: (state) => state.media.loadingListStatus,
  loadingMediaStatus: (state) => state.media.loadingMediaStatus,
};

export { selectors };
