import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';
import { apiWrapper } from '../../api/apiWrapper';

const thunks = {
  getClassInfo: createThunk('classDetails/getClassInfo', ({ classId }) =>
    apiWrapper.get(WEB_API_ROUTES.classes.info.replace('{classId}', classId))
  ),

  getClassAppointments: createThunk(
    'classDetails/getClassAppointments',
    ({ classId }) =>
      api.get(WEB_API_ROUTES.classes.appointments.replace('{classId}', classId))
  ),

  updateClassInfo: createThunk(
    'classDetails/updateClassInfo',
    ({ classId, data }) =>
      api.put(WEB_API_ROUTES.classes.info.replace('{classId}', classId), data)
  ),

  checkClassSchedule: createThunk('classDetails/checkClassSchedule', (data) =>
    api.post(WEB_API_ROUTES.classes.scheduleCheck, data)
  ),

  saveClassSchedule: createThunk(
    'classDetails/saveClassSchedule',
    ({ classId, data }) =>
      api.post(
        WEB_API_ROUTES.classes.schedule.replace('{classId}', classId),
        data
      ),
    'The Schedule data is saved'
  ),

  getClassScheduleList: createThunk(
    'classDetails/getClassScheduleList',
    (classId) =>
      api.get(WEB_API_ROUTES.classes.schedule.replace('{classId}', classId))
  ),

  updateClassScheduleInfo: createThunk(
    'classDetails/updateClassScheduleInfo',
    ({ classRoomId, data }) =>
      api.put(
        WEB_API_ROUTES.classes.schedule.replace('{classId}', classRoomId),
        data
      ),
    'Changes made are saved'
  ),

  getClassDancers: createThunk('classDetails/getClassDancers', (classId) =>
    api.get(WEB_API_ROUTES.classes.dancers.replace('{classId}', classId))
  ),

  updateClassDancers: createThunk(
    'classDetails/updateClassDancers',
    ({ classId, dancers }) =>
      api.put(
        WEB_API_ROUTES.classes.updateDancers.replace('{classId}', classId),
        { dancers }
      )
  ),

  deleteClass: createThunk('classDetails/deleteClass', (classId) =>
    api.delete(WEB_API_ROUTES.classes.info.replace('{classId}', classId))
  ),

  creatingClassPricing: createThunk(
    'classDetails/creatingClassPricing',
    ({ classId, data }) =>
      api.post(
        WEB_API_ROUTES.classes.pricing.replace('{classId}', classId),
        data
      ),
    'The payment options were updated'
  ),

  getClassPricing: createThunk('classDetails/getClassPricing', ({ classId }) =>
    api.get(WEB_API_ROUTES.classes.pricing.replace('{classId}', classId))
  ),

  updateClassPricing: createThunk(
    'classDetails/updateClassPricing',
    ({ id, data }) =>
      api.put(WEB_API_ROUTES.classes.pricing.replace('{classId}', id), data),
    'The payment options were updated'
  ),

  setClassActivation: createThunk(
    'classDetails/setClassActivation',
    ({ classId, is_active }) =>
      api.put(
        WEB_API_ROUTES.classes.toggleActive.replace('{classId}', classId),
        { is_active }
      ),
    'Class status was updated'
  ),
};

export { thunks };
