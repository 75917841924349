const guardians = (state) => {
  const pending = state.dancerDetails.relationships.filter(
    (g) => g.status === 'pending'
  );
  const saved = state.dancerDetails.relationships.filter(
    (g) => g.status === 'saved'
  );

  return { pending, saved };
};

const selectors = {
  guardians,
  dancerInfo: (state) => state.dancerDetails.dancerInfo,
  relationships: (state) => state.dancerDetails.relationships,
  notifications: (state) => state.dancerDetails.notifications,
  createGuardianStatus: (state) => state.dancerDetails.createGuardianStatus,
  dancerEvaluation: (state) => state.dancerDetails.dancerEvaluation,
  dancerUpcomingAppointments: (state) =>
    state.dancerDetails.dancerUpcomingAppointments,
  loadingAppointmentsStatus: (state) =>
    state.dancerDetails.loadingAppointmentsStatus,

  ratings: (state) => state.dancerDetails.ratings,
  dancerClasses: (state) => state.dancerDetails.dancerClasses,

  routines: {
    list: (state) => state.dancerDetails.routines.list,
    loadingDancerRoutinesStatus: (state) =>
      state.dancerDetails.routines.loadingDancerRoutinesStatus,
    currentPage: (state) => state.dancerDetails.routines.currentPage,
  },

  performances: {
    dancerPerformances: (state) =>
      state.dancerDetails.performances.dancerPerformances,
    currentPage: (state) => state.dancerDetails.performances.currentPage,
    loadingPerformanceStatus: (state) =>
      state.dancerDetails.performances.loadingPerformanceStatus,
  },
  media: {
    list: (state) => state.dancerDetails.media.list,
    filters: (state) => state.dancerDetails.media.filters,
    pagination: (state) => state.dancerDetails.media.pagination,
    loadingMediaListStatus: (state) =>
      state.dancerDetails.media.loadingMediaListStatus,
  },

  loadingNotificationsStatus: (state) =>
    state.dancerDetails.loadingNotificationsStatus,
  loadingDancerInfoStatus: (state) =>
    state.dancerDetails.loadingDancerInfoStatus,
  getEvaluationStatus: (state) => state.dancerDetails.getEvaluationStatus,
  loadingClassesStatus: (state) => state.dancerDetails.loadingClassesStatus,
  loadingRatingStatus: (state) => state.dancerDetails.loadingRatingStatus,
};

export { selectors };
