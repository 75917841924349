import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from 'utils/storeUtils';
import { apiWrapper } from 'api/apiWrapper';

export const thunks = {
  getMediaInfo: createThunk('mediaDetails/getMediaInfo', (mediaId) =>
    apiWrapper.get(WEB_API_ROUTES.media.info.replace('{mediaId}', mediaId))
  ),

  edit: createThunk('mediaDetails/edit', ({ mediaId, data }) =>
    api.put(WEB_API_ROUTES.media.info.replace('{mediaId}', mediaId), data)
  ),

  deleteMedia: createThunk('mediaDetails/deleteMedia', (mediaId) =>
    api.delete(WEB_API_ROUTES.media.info.replace('{mediaId}', mediaId))
  ),
};
