import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  profile: null,
  isSidebarOpen: false,
  recipientsList: [],
  teachers: [],
  rooms: [],
  states: [],
  cities: [],
  users: [],
  countries: [],
  search: null,
  students: [],
  tags: [],
  nicknames: [],

  routineConsts: {
    statuses: [],
    types: [],
    levels: [],
    styles: [],
  },
  fetchingStatesStatus: status.IDLE,
  fetchingCitiesStatus: status.IDLE,
  fetchingSearchStatus: status.IDLE,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setSidebarOpen: (state, { payload }) => {
      state.isSidebarOpen = payload;
    },
    clearSearch: (state) => {
      state.search = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getStates.fulfilled, (state, { payload }) => {
        state.states = payload.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        state.fetchingStatesStatus = status.SUCCESS;
      })

      .addCase(thunks.getCities.fulfilled, (state, { payload }) => {
        state.cities = payload.data;
        state.fetchingCitiesStatus = status.SUCCESS;
      })

      .addCase(thunks.getRooms.fulfilled, (state, { payload }) => {
        state.rooms = payload.data;
      })

      .addCase(thunks.getTeachers.fulfilled, (state, { payload }) => {
        state.teachers = payload.data;
      })

      .addCase(thunks.getStudents.fulfilled, (state, { payload }) => {
        state.students = payload.data;
      })

      .addCase(thunks.getUsers.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.users = data;
      })

      .addCase(thunks.search.pending, (state) => {
        state.fetchingSearchStatus = status.PENDING;
      })
      .addCase(thunks.search.fulfilled, (state, { payload }) => {
        state.search = payload.data;
        state.fetchingSearchStatus = status.SUCCESS;
      })
      .addCase(thunks.search.rejected, (state) => {
        state.fetchingSearchStatus = status.FAIL;
      })

      .addCase(thunks.getProfile.fulfilled, (state, { payload }) => {
        state.profile = payload.data;
      })

      .addCase(thunks.getRoutineTypes.fulfilled, (state, { payload }) => {
        state.routineConsts.types = payload.data?.map((t) => ({
          value: t,
          label: t,
        }));
      })

      .addCase(thunks.getRoutineStatuses.fulfilled, (state, { payload }) => {
        state.routineConsts.statuses = payload.data?.map((s) => ({
          value: s,
          label: s,
        }));
      })

      .addCase(thunks.getRoutineLevels.fulfilled, (state, { payload }) => {
        state.routineConsts.levels = payload.data?.map((l) => ({
          value: l,
          label: l,
        }));
      })

      .addCase(thunks.getStyles.fulfilled, (state, { payload }) => {
        state.styles = payload.data?.map((s) => ({
          value: s.id,
          label: s.name,
        }));
      })

      .addCase(thunks.getRecipientsList.fulfilled, (state, { payload }) => {
        const preparedRecipients = payload.data.map((r) => ({
          ...r,
          value: r.userId,
          label: r.fullName,
        }));

        state.recipientsList = preparedRecipients;
      })

      .addCase(thunks.getTags.fulfilled, (state, { payload }) => {
        state.tags = payload.data;
      })

      .addCase(thunks.getNicknames.fulfilled, (state, { payload }) => {
        state.nicknames = payload.data;
      });
  },
});

const general = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { general };
export default slice.reducer;
