import { WEB_API_ROUTES } from 'api/api-routes';
import api from 'api';
import { createThunk } from '../../utils/storeUtils';

export const thunks = {
  gePerformanceInfo: createThunk(
    'performanceDetails/gePerformanceInfo',
    (performanceId) =>
      api.get(
        WEB_API_ROUTES.performances.performance.replace(
          '{performanceId}',
          performanceId
        )
      )
  ),

  updatePerformanceInfo: createThunk(
    'performanceDetails/updatePerformanceInfo',
    ({ performanceId, data }) =>
      api.put(
        WEB_API_ROUTES.performances.performance.replace(
          '{performanceId}',
          performanceId
        ),
        data
      )
  ),

  getDancersList: createThunk(
    'performanceDetails/getDancersList',
    ({ routineId, performanceId }) =>
      api.get(
        WEB_API_ROUTES.performances.dancersList
          .replace('{routineId}', routineId)
          .replace('{performanceId}', performanceId)
      )
  ),

  getPerformanceDancers: createThunk(
    'performanceDetails/getPerformanceDancers',
    (performanceId) =>
      api.get(
        WEB_API_ROUTES.performances.performanceDancers.replace(
          '{performanceId}',
          performanceId
        )
      )
  ),

  deletePerformance: createThunk(
    'performanceDetails/deletePerformance',
    (performanceId) =>
      api.delete(
        WEB_API_ROUTES.performances.info.replace(
          '{performanceId}',
          performanceId
        )
      )
  ),

  updatePerformanceDancers: createThunk(
    'performanceDetails/updatePerformanceDancers',
    ({ performanceId, dancers }) =>
      api.put(
        WEB_API_ROUTES.performances.updatePerformanceDancers.replace(
          '{performanceId}',
          performanceId
        ),
        { dancers }
      )
  ),
};
