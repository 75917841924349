import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { mediaSizes, mediaTypes } from 'utils/media';
import { thunks } from './thunks';
import { selectors } from './selectors';

const defaultValues = {
  type: mediaTypes.BOTH,
  dancerSearch: { value: '', label: '' },
  dancers: [],
  classSearch: { value: '', label: '' },
  classes: [],
  eventSearch: { value: '', label: '' },
  events: [],
  routineSearch: { value: '', label: '' },
  routines: [],
  date_start: '',
  date_end: '',
};

const initialState = {
  mediaSize: mediaSizes.S,
  list: [],
  filters: {
    fields: defaultValues,
    updatedFields: 0,
  },
  pagination: {
    total: 0,
    uploadedCount: 20,
  },
  loadingListStatus: status.IDLE,
  loadingMediaStatus: status.IDLE,
};

export const calculateFiltersCount = (defaultValues, payload) => {
  let updatedFieldsCount = 0;

  Object.keys(payload).forEach((key) => {
    if (key.includes('Search')) {
      return;
    }

    if (Array.isArray(defaultValues[key])) {
      if (payload[key].length !== defaultValues[key].length) {
        updatedFieldsCount += 1;
      }
      return;
    }

    if (typeof defaultValues[key] === 'object') {
      if (payload[key]?.value !== defaultValues[key]?.value) {
        updatedFieldsCount += 1;
      }
      return;
    }

    if (payload[key] !== defaultValues[key]) {
      updatedFieldsCount += 1;
    }
  });

  return updatedFieldsCount;
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilters: (state, { payload }) => {
      state.filters.updatedFields = calculateFiltersCount(
        defaultValues,
        payload
      );

      state.filters.fields = payload;
    },
    setMediaSize: (state, { payload }) => {
      state.mediaSize = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getList.pending, (state) => {
        state.loadingListStatus = status.PENDING;
      })
      .addCase(thunks.getList.fulfilled, (state, { payload }) => {
        state.loadingListStatus = status.SUCCESS;

        state.pagination.total = payload.totalFilteredCount;
        state.pagination.uploadedCount =
          payload.limitedCount < initialState.pagination.uploadedCount
            ? initialState.pagination.uploadedCount
            : payload.limitedCount;

        state.list = payload.data;
      })
      .addCase(thunks.getList.rejected, (state) => {
        state.loadingListStatus = status.FAIL;
      })

      .addCase(thunks.addMedia.pending, (state) => {
        state.loadingMediaStatus = status.PENDING;
      })
      .addCase(thunks.addMedia.fulfilled, (state) => {
        state.loadingMediaStatus = status.SUCCESS;
      })
      .addCase(thunks.addMedia.rejected, (state) => {
        state.loadingMediaStatus = status.FAIL;
      });
  },
});

const media = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { media };
export default slice.reducer;
