import { WEB_API_ROUTES } from 'api/api-routes';
import { createThunk } from 'utils/storeUtils';
import { apiWrapper } from 'api/apiWrapper';
import { mediaTypes } from 'utils/media';
import api from '../../api';

const getValues = (arr) => arr.map((item) => item.value);

export const thunks = {
  getList: createThunk('media/getList', (shouldGetNext, { getState }) => {
    const { fields } = getState().media.filters;
    const { uploadedCount } = getState().media.pagination;

    const params = {
      type:
        fields.type.value === mediaTypes.BOTH.value
          ? undefined
          : fields.type.value,
      dancers: getValues(fields.dancers),
      classes: getValues(fields.classes),
      events: getValues(fields.events),
      routines: getValues(fields.routines),
      dateRangeFrom: fields.date_start,
      dateRangeTo: fields.date_end,
      limit: shouldGetNext ? uploadedCount + 20 : uploadedCount,
    };

    return apiWrapper.get(WEB_API_ROUTES.media.list, { params });
  }),
  addMedia: createThunk('media/addMedia', (data) =>
    api.post(WEB_API_ROUTES.media.list, data)
  ),
};
