export const mediaSizes = { S: 'S', L: 'L' };

export const mediaTypes = {
  PHOTO: {
    value: 'photo',
    label: 'Photo',
  },
  VIDEO: {
    value: 'video',
    label: 'Video',
  },
  BOTH: {
    value: 'both',
    label: 'Both',
  },
};
