import axios from 'axios';
import qs from 'qs';
import store from 'store';
import { auth as authSlice } from 'store/auth';
import { showToast } from 'utils/showToast';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  timeout: 30000,
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: 'brackets',
      skipNulls: true,
    }),
});

instance.interceptors.request.use((config) => {
  const { auth } = store.getState();

  if (auth.token) config.headers.Authorization = `Bearer ${auth.token}`;

  return config;
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefresh = (token) => {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

// logout interceptor
instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const { status } = err.response || {};
    const originalRequest = err.config;

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        const { auth } = store.getState();

        try {
          const response = await store
            .dispatch(authSlice.thunks.refreshToken(auth.refreshToken))
            .unwrap();

          isRefreshing = false;
          const newToken = response.accessToken;

          onRefresh(newToken);

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          store.dispatch(authSlice.actions.setUnauthorized());
          showToast.error('Unauthorized');
          return Promise.reject(refreshError);
        }
      }

      return new Promise((resolve) => {
        addRefreshSubscriber((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    if (status === 423) {
      window.location.href = 'auth/login';
    }

    return Promise.reject(err);
  }
);

export default instance;
