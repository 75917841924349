import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  mediaInfo: {},
  loadingMediaStatus: status.IDLE,

  mediaDeletingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'mediaDetails',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    resetStatus(state, { payload }) {
      state.schedule[payload] = status.IDLE;
    },
    setAudioUrl(state, { payload }) {
      state.audioUrl = payload;
    },
    setTablePage: (state, { payload }) => {
      state.schedule.pagination.current = payload;
    },
    setDancersPage: (state, { payload }) => {
      state.dancers.currentPage = payload;
    },
    setPerformancesPage: (state, { payload }) => {
      state.performances.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getMediaInfo.pending, (state) => {
        state.loadingMediaStatus = status.PENDING;
      })
      .addCase(thunks.getMediaInfo.fulfilled, (state, { payload }) => {
        state.mediaInfo = payload.data;
        state.loadingMediaStatus = status.SUCCESS;
      })
      .addCase(thunks.getMediaInfo.rejected, (state) => {
        state.loadingMediaStatus = status.FAIL;
      })

      .addCase(thunks.deleteMedia.pending, (state) => {
        state.mediaDeletingStatus = status.PENDING;
      })
      .addCase(thunks.deleteMedia.fulfilled, (state) => {
        state.mediaDeletingStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteMedia.rejected, (state) => {
        state.mediaDeletingStatus = status.FAIL;
      });
  },
});

const mediaDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { mediaDetails };
export default slice.reducer;
