import { useLayoutEffect } from 'react';

export const useResizableTextArea = ({
  resizableWithText,
  textareaRef,
  value,
  height,
}) => {
  useLayoutEffect(() => {
    if (resizableWithText && textareaRef.current) {
      const textarea = textareaRef.current;
      const lineHeight = 24;

      const rows = value.split('\n').length;
      const newHeight = rows * lineHeight + 50;

      textarea.style.height = `${Math.max(newHeight, height)}px`;
    }
  }, [value, resizableWithText]);
};
