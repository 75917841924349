import styled from 'styled-components';

import { TextEllipsis } from 'components/App/GlobalStyled';

// Page styled
const DetailsPageStyled = styled.div`
  padding: 29px 25px 50px 22px;
`;

const HeaderInfoWrapperStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const InfoColumnsWrapperStyled = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 392px minmax(0, 1fr);
  gap: 21px;
  margin-top: 24px;
  ${({ css }) => css};
`;

const InfoColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    width: 24px;
    margin-right: 16px;
  }

  strong {
    color: ${({ theme }) => theme.gray950};
  }

  p {
    color: ${({ theme }) => theme.gray600};
  }

  ${({ css }) => css}
`;

// Entity Info
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const EditButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${Row} {
    display: flex;

    strong {
      display: inline-block;
      margin-bottom: 8px;
    }

    p {
      ${TextEllipsis({ width: '304px' })}
    }
  }
`;

const ChevronContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ css }) => css}
`;

const RelativeRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-size: 16px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 8px;

    strong {
      font-weight: 600;
    }

    span {
      cursor: pointer;
      font-weight: 500;
      color: ${({ theme }) => theme.gray600};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ${ChevronContainer} {
    margin-left: auto;
  }
`;

const Item = styled.div`
  display: flex;
  column-gap: 16px;
  font-size: 16px;

  p {
    margin-bottom: 8px;
  }

  span {
    display: inline-block;
    ${TextEllipsis({ width: '110px' })};
    color: ${({ theme }) => theme.gray600};
    font-weight: 500;
  }
`;

export {
  DetailsPageStyled,
  HeaderInfoWrapperStyled,
  InfoColumnsWrapperStyled,
  InfoColumnStyled,
  Wrap,
  Row,
  ChevronContainer,
  EditButton,
  PersonalInfo,
  RelativeRow,
  Item,
};
