const BASE_URLS = {
  auth: '/auth',
  signIn: '/sign-in',
  signUp: '/sign-up',
  profile: '/profile',
  classes: '/classes',
  routines: '/routines',
  events: '/events',
  calendar: '/calendar',
  dancers: '/dancers',
  parents: '/parents',
  payments: '/payment',
  media: '/media',
  notification: '/notification',
  settings: '/settings',
  performances: '/performances',
  studio: '/studio',
};

export const WEB_API_ROUTES = {
  general: {
    search: '/search',
    getStates: '/location/states',
    getCities: '/location/cities',
    students: 'data/students',
    users: 'studio/users',
    rooms: 'data/rooms',
    teachers: 'data/teachers',
    profile: '/profile',
    routineStatuses: 'data/statuses',
    routineTypes: 'data/types',
    routineLevels: 'data/levels',
    styles: 'data/styles',
    tags: '/data/tags',
    nicknames: '/data/nicknames',
    getTagsAndNicknames: '/search/nicknames-and-tags',
  },

  auth: {
    logIn: `${BASE_URLS.signIn}/login`,
    logOut: `${BASE_URLS.profile}/logout`,
    refreshToken: `${BASE_URLS.signIn}/refresh`,
    googleLogin: `${BASE_URLS.signIn}/with-google`,
    appleLogin: `${BASE_URLS.signIn}/with-apple`,
    forgotPassword: `${BASE_URLS.signIn}/forgot-password`,
    createPassword: `${BASE_URLS.signIn}/create-password`,
    validateSignUp: `${BASE_URLS.signUp}/validate`,
    googleSignUp: `${BASE_URLS.signUp}/with-google`,
    appleSignUp: `${BASE_URLS.signUp}/with-apple`,
  },

  registration: {
    signUp: BASE_URLS.signUp,
    verifyCode: `${BASE_URLS.signUp}/verify-code`,
    sendVerifyCode: `${BASE_URLS.signUp}/send-verify-code`,
  },

  schedule: {
    teachers: `${BASE_URLS.calendar}/teachers`,
    students: `${BASE_URLS.calendar}/students`,
    parents: `${BASE_URLS.calendar}/parents`,
    classes: `${BASE_URLS.calendar}/classes`,
    appointments: `${BASE_URLS.calendar}/appointments`,
    createAppointment: `${BASE_URLS.calendar}/appointments/store`,
    updateAppointment: `${BASE_URLS.calendar}/appointments/update`,
    deleteAppointment: `${BASE_URLS.calendar}/appointments/delete/{appointmentId}`,
    cancelAppointment: `${BASE_URLS.calendar}/appointments/cancel-and-notify/{appointmentId}`,
    updateDancerRating: `${BASE_URLS.calendar}/appointments/ratings/update`,
    storeInternal: `${BASE_URLS.calendar}/appointments/store-internal`,
    updateInternal: `${BASE_URLS.calendar}/appointments/update-internal/{appointmentId}`,
    storeUnavailable: `${BASE_URLS.calendar}/appointments/store-unavailable`,
    updateUnavailable: `${BASE_URLS.calendar}/appointments/update-unavailable/{appointmentId}`,
  },

  dancers: {
    list: BASE_URLS.dancers,
    update: `${BASE_URLS.dancers}/{dancerId}/update`,
    info: `${BASE_URLS.dancers}/{dancerId}`,
    evaluation: `${BASE_URLS.dancers}/{dancerId}/evaluation`,
    ratings: `${BASE_URLS.dancers}/{dancerId}/ratings`,
    performance: `${BASE_URLS.dancers}/{dancerId}/performances`,
    notifications: `${BASE_URLS.dancers}/{dancerId}/notifications`,
    email: `${BASE_URLS.dancers}/notification/email`,
    sms: `${BASE_URLS.dancers}/notification/phone`,
    routines: `${BASE_URLS.dancers}/{dancerId}/routines`,
    appointments: `${BASE_URLS.dancers}/{dancerId}/appointments`,
    removeAvatar: `${BASE_URLS.dancers}/{dancerId}/remove-avatar`,
    classes: `${BASE_URLS.dancers}/{dancerId}/classes`,
    createRelative: `${BASE_URLS.dancers}/relatives/store`,
    media: `${BASE_URLS.dancers}/{dancerId}/media`,
  },

  classes: {
    list: BASE_URLS.classes,
    dancers: `${BASE_URLS.classes}/{classId}/dancers`,
    updateDancers: `${BASE_URLS.classes}/{classId}/dancers/join`,
    info: `${BASE_URLS.classes}/{classId}`,
    pricing: `${BASE_URLS.classes}/{classId}/pricing-options`,
    appointments: `${BASE_URLS.classes}/{classId}/upcoming-appointments`,
    styles: `${BASE_URLS.classes}/styles`,
    schedule: `${BASE_URLS.classes}/{classId}/schedule`,
    scheduleCheck: `${BASE_URLS.classes}/schedule/check`,
    toggleActive: `${BASE_URLS.classes}/{classId}/set-activation`,
    recipientsList: `${BASE_URLS.classes}/{classId}/notification/get-recipients-list`,
    email: `${BASE_URLS.classes}/{classId}${BASE_URLS.notification}/email`,
    sms: `${BASE_URLS.classes}/{classId}${BASE_URLS.notification}/phone`,
  },

  routines: {
    list: BASE_URLS.routines,
    routineInfo: `${BASE_URLS.routines}/{routineId}`,
    audio: `${BASE_URLS.routines}/{routineId}/audio`,
    dancers: `${BASE_URLS.routines}/{routineId}/dancers`,
    updateDancers: `${BASE_URLS.routines}/{routineId}${BASE_URLS.dancers}/join`,
    performances: `${BASE_URLS.routines}/{routineId}/performances/list`,
    performancesInfo: `${BASE_URLS.routines}/{routineId}/performances`,
  },

  events: {
    list: BASE_URLS.events,
    info: `${BASE_URLS.events}/{eventId}`,
    performances: `${BASE_URLS.events}/{eventId}/performances/list`,
    recipientsList: `${BASE_URLS.events}/{eventId}${BASE_URLS.notification}/get-recipients-list`,
    sendEmail: `${BASE_URLS.events}/{eventId}${BASE_URLS.notification}/email`,
    sendSms: `${BASE_URLS.events}/{eventId}${BASE_URLS.notification}/phone`,
    performancesInfo: `${BASE_URLS.events}/{eventId}/performances`,
  },

  notifications: {
    sendEmail: `${BASE_URLS.notification}/email`,
    sendSms: `${BASE_URLS.notification}/phone`,
    recipientsList: `${BASE_URLS.notification}/get-recipients-list`,
  },

  payments: {
    checkout: `${BASE_URLS.payments}/stripe/checkout`,
    createIntent: `${BASE_URLS.payments}/stripe/create-intent`,
    addMethod: `${BASE_URLS.payments}/add-payment-method`,
    listTransactions: `${BASE_URLS.payments}/transactions`,
  },

  settings: {
    config: `${BASE_URLS.studio}${BASE_URLS.settings}`,
    deleteLocation: `${BASE_URLS.studio}${BASE_URLS.settings}/{locationId}`,
    deleteRoom: `${BASE_URLS.studio}${BASE_URLS.settings}/room/{roomId}`,
  },

  performances: {
    info: `${BASE_URLS.performances}/{performanceId}`,
    delete: `${BASE_URLS.performances}/delete/{performanceId}`,
    routineDancers: `${BASE_URLS.performances}/dancers/list/{routineId}/{performanceId}`,
    createEventPerformance: `${BASE_URLS.events}/performances/{eventId}`,
    performance: `${BASE_URLS.performances}/{performanceId}`,
    performanceDancers: `${BASE_URLS.performances}/{performanceId}/dancers`,
    updatePerformanceDancers: `${BASE_URLS.performances}/{performanceId}/dancers/join`,
    dancersList: `${BASE_URLS.performances}/{performanceId}/dancers/{routineId}/list`,
  },

  parents: {
    list: BASE_URLS.parents,
    sendEmail: `${BASE_URLS.parents}/notification/email`,
    sendSms: `${BASE_URLS.parents}/notification/phone`,
    getRelatives: `${BASE_URLS.parents}/relatives/list`,
    createRelative: `${BASE_URLS.parents}/store-relative`,
    parentInfo: `${BASE_URLS.parents}/{parentId}`,
    dancers: `${BASE_URLS.parents}/{parentId}/dancers`,
    notifications: `${BASE_URLS.parents}/{parentId}/notifications`,
    classes: `${BASE_URLS.parents}/{parentId}/classes`,
  },

  media: {
    list: `${BASE_URLS.media}`,
    info: `${BASE_URLS.media}/{mediaId}`,
  },
};
